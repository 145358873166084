import { Button, Col, Form, Input, message, Popconfirm, Row, Select, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { PrimaryAttributesType } from "../../pages/TopProductAttributes";
import { useForm } from "antd/es/form/Form";
import axios from "axios";
import { ExclamationCircleOutlined, FieldNumberOutlined, PlusOutlined } from "@ant-design/icons";
import ApplyToAllCreateNewValue from "./ApplyToAllCreateNewValue";
import { RootStore } from "../../../../Store";
import openNotification from "../../../../shared/MessagesInfo/WarningBox";
import numberWithCommas from "../../../../shared/utils/numberUtils";

interface ApplyToAllProps {
    primaryAttributes: PrimaryAttributesType<{
        allowed_value_id: number;
        value: string;
    }>[];
    setPrimaryAttributes: React.Dispatch<React.SetStateAction<PrimaryAttributesType<{ allowed_value_id: number; value: string }>[]>>;
    currentCountryName: string;
    currentCountryId: number;
    selectedCategory: number | undefined;
    searchData: { search: string | undefined; search_include: string[]; search_exclude: string[]; or: boolean } | undefined;
    getProducts: () => Promise<void>;
    stateProduct: RootStore;
}

const ApplyToAll = (props: ApplyToAllProps) => {
    const { primaryAttributes, setPrimaryAttributes, currentCountryName, currentCountryId, selectedCategory, searchData, getProducts, stateProduct } = props;
    const [isPopConfirmOpen, setIsPopConfirmOpen] = useState(false);
    const [popconfirmData, setPopconfirmData] = useState<{ name: string | undefined; value: any }[] | undefined>([]);

    const [form] = useForm();

    const submitForm = async () => {
        const e = form.getFieldsValue();
        const isAnyFieldFilled = Object.values(e).some((value) => value !== undefined && value !== "" && value !== null);
        if (!isAnyFieldFilled) {
            openNotification("At least one field has to be filled!");
            return;
        }

        for (let key in e) {
            const findPrimaryAttribute = primaryAttributes.find((attr) => attr[`name_${currentCountryName}`] === key);
            if (findPrimaryAttribute?.type === "range") {
                if (typeof e[key] === "string" && e[key].length > 0 && e[key].trim().length === 0) {
                    openNotification(
                        <div>
                            Attributes <span style={{ fontWeight: "bold" }}>{findPrimaryAttribute[`name_${currentCountryName}`].toString()}</span> doesn't have valid type!
                        </div>
                    );
                    return;
                }
            }
            if (findPrimaryAttribute?.type === "range" && e[key] && e[key].toString().trim().length > 0 && isNaN(Number(e[key]))) {
                openNotification(
                    <div>
                        Attribute <span style={{ fontWeight: "bold" }}>{findPrimaryAttribute[`name_${currentCountryName}`].toString()}</span> value must be a number!
                    </div>
                );
                return;
            }
            if (
                findPrimaryAttribute?.type === "range" &&
                !isNaN(Number(e[key])) &&
                (Number(e[key]) < Number(findPrimaryAttribute.min_value) || Number(e[key]) > Number(findPrimaryAttribute.max_value))
            ) {
                if (typeof e[key] === "string" && e[key].trim().length === 0) {
                    continue;
                }
                openNotification(
                    `Range for ${findPrimaryAttribute[`name_${currentCountryName}`]} attriubte have to be between ${numberWithCommas(
                        findPrimaryAttribute.min_value.replace(".", ",")
                    )} and ${numberWithCommas(findPrimaryAttribute.max_value.replace(".", ","))}`
                );
                return;
            }
        }

        for (let key in e) {
            if (e[key]) {
                const findPrimaryAttribute = primaryAttributes.find((attr) => attr[`name_${currentCountryName}`] === key);

                const obj = {
                    l3_id: selectedCategory,
                    attribute_id: findPrimaryAttribute?.id,
                    attribute_type: findPrimaryAttribute?.type === "yesno" ? "yesNo" : findPrimaryAttribute?.type,
                    attribute_value_id: findPrimaryAttribute?.type === "range" ? null : findPrimaryAttribute?.type === "multiselect" && Array.isArray(e[key]) ? e[key][0] : e[key],
                    value: findPrimaryAttribute?.type === "range" ? Number(e[key]) : 0,
                    search_query: searchData?.search,
                    country_id: currentCountryId,
                    search_include: searchData?.search_include && searchData?.search_include.length > 0 ? searchData?.search_include?.map((text) => text) : [],
                    search_exclude: searchData?.search_exclude && searchData?.search_exclude.length > 0 ? searchData?.search_exclude?.map((text) => text) : [],
                    ...(searchData?.or && { or: 1 }),
                };

                try {
                    const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/attributes/mass-assign-attributes-to-products`, obj);
                    message.success({
                        onClose() {},
                        style: { marginTop: "8rem", zIndex: 1000 },
                        content: (
                            <div>
                                Apply <span style={{ fontWeight: "bold" }}>{findPrimaryAttribute ? findPrimaryAttribute[`name_${currentCountryName}`].toString() : ""}</span> <br />
                                to <span style={{ color: "darkred", fontWeight: "bold" }}>{data.data.assigned}</span> products
                            </div>
                        ),
                        duration: 7,
                    });
                } catch (err) {
                    console.log(err);
                }
            }
        }
        getProducts();
    };

    const [openModal, setOpenModal] = useState(false);
    const [newAttributeValue, setNewAttributeValue] = useState<{ name: string | undefined; attribute_id: number | undefined /*product: ProductType*/ } | null>(null);

    const handleOpenModal = (field: string) => {
        setOpenModal(true);
        setNewAttributeValue({ name: field, attribute_id: primaryAttributes.find((attr) => attr[`name_${currentCountryName}`] === field)?.id });
    };

    const openNewValueModal = (field: any) => {
        setTimeout(() => {
            form.resetFields([field]);
            handleOpenModal(field);
        }, 100);
    };

    const changeSelect = (e: string, field: any) => {
        if (e === "add-button") {
            setTimeout(() => {
                form.resetFields([field]);
                handleOpenModal(field);
            }, 100);
        }
    };

    const handleCancel = () => {
        setOpenModal(false);
    };

    const resetForm = () => {
        form.resetFields();
    };

    useEffect(() => {
        if (isPopConfirmOpen) {
            const formFields = form.getFieldsValue();
            let attriubtesForSubmit: { name: string | undefined; value: any }[] = [];

            for (let key in formFields) {
                if (formFields[key]) {
                    const findPrimaryAttribute: any = primaryAttributes.find((attr) => attr[`name_${currentCountryName}`] === key);
                    // console.log(findPrimaryAttribute?.type === "range" && formFields[key]);
                    attriubtesForSubmit.push({
                        name: findPrimaryAttribute[`name_${currentCountryName}`] as string,
                        value:
                            findPrimaryAttribute?.type === "range"
                                ? formFields[key].toString().trim().length > 0 && !isNaN(Number(formFields[key]))
                                    ? Number(formFields[key])
                                    : "Not valid"
                                : findPrimaryAttribute.attribute_values.find((attr: any) => attr.allowed_value_id === Number(formFields[key])).value,
                    });
                }
            }
            setPopconfirmData(attriubtesForSubmit);
        } else {
            const timer = setTimeout(() => {
                setPopconfirmData(undefined);
            }, 300);

            clearTimeout(timer);
        }
    }, [isPopConfirmOpen, currentCountryName, form, primaryAttributes]);

    return (
        <Col span={23} style={{ border: "1px solid lightgray", padding: "1rem", marginTop: "1rem", borderRadius: "8px", backgroundColor: "#F5EFE7" }}>
            <Typography.Title level={4}>Apply to all attributes</Typography.Title>
            <ApplyToAllCreateNewValue
                openModal={openModal}
                handleCancel={handleCancel}
                newAttributeValue={newAttributeValue}
                countries={stateProduct.countries}
                currentCountryName={currentCountryName}
                setPrimaryAttributes={setPrimaryAttributes}
            />
            <Form layout="inline" form={form} /*onFinish={submitForm}*/>
                <Row align="middle">
                    {primaryAttributes.map((attr) => (
                        <div key={attr.id}>
                            {attr.type === "range" ? (
                                <div style={{ marginBottom: "0.5rem" }}>
                                    <span style={{ marginLeft: "5px" }}>
                                        {attr[`name_${currentCountryName}`] as string}{" "}
                                        <span style={{ color: "blue" }}>
                                            ({numberWithCommas(attr.min_value.replace(".", ","))} - {numberWithCommas(attr.max_value.replace(".", ","))})
                                        </span>
                                    </span>
                                    {": "}
                                    <Form.Item
                                        // style={{ display: "flex", flexDirection: "column" }}
                                        name={attr[`name_${currentCountryName}`] as string}
                                        // label={attr[`name_${currentCountryName}`] as string}
                                        style={{ width: "400px" }}
                                    >
                                        <Input placeholder={attr[`name_${currentCountryName}`] as string} allowClear addonAfter={<FieldNumberOutlined />} />
                                    </Form.Item>
                                </div>
                            ) : attr.type === "select" ? (
                                <div style={{ marginBottom: "0.5rem" }}>
                                    <span style={{ marginLeft: "5px" }}>{attr[`name_${currentCountryName}`] as string}</span>
                                    {": "}
                                    <Form.Item style={{ width: "400px" }} name={attr[`name_${currentCountryName}`] as string}>
                                        <Select
                                            showSearch
                                            style={{ width: "400px" }}
                                            placeholder={attr[`name_${currentCountryName}`] as string}
                                            allowClear
                                            onChange={(e) => changeSelect(e, attr[`name_${currentCountryName}`])}
                                            optionFilterProp="label"
                                            filterOption={(input, option: any) => (option?.value === "add-button" ? false : (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase()))}
                                        >
                                            {Array.isArray(attr.attribute_values)
                                                ? attr.attribute_values.map(
                                                      (a: PrimaryAttributesType<{ allowed_value_id: number; value: string }>) =>
                                                          a.allowed_value_id &&
                                                          a.value && (
                                                              <Select.Option key={a.allowed_value_id.toString()} value={a.allowed_value_id.toString()}>
                                                                  {a.value.toString()}
                                                              </Select.Option>
                                                          )
                                                  )
                                                : []}
                                            <Select.Option
                                                value={"add-button"}
                                                children={
                                                    <Button size="small" onClick={() => openNewValueModal(attr[`name_${currentCountryName}`])} icon={<PlusOutlined />}>
                                                        Add New
                                                    </Button>
                                                }
                                            />
                                        </Select>
                                    </Form.Item>
                                </div>
                            ) : attr.type === "yesno" ? (
                                <div style={{ marginBottom: "0.5rem" }}>
                                    <span style={{ marginLeft: "5px" }}>{attr[`name_${currentCountryName}`] as string}</span>
                                    {": "}
                                    <Form.Item style={{ width: "400px" }} name={attr[`name_${currentCountryName}`] as string}>
                                        <Select style={{ width: "400px" }} placeholder={attr[`name_${currentCountryName}`] as string} allowClear>
                                            {Array.isArray(attr.attribute_values)
                                                ? attr.attribute_values.map(
                                                      (a: PrimaryAttributesType<{ allowed_value_id: number; value: string }>) =>
                                                          a.allowed_value_id &&
                                                          a.value && (
                                                              <Select.Option key={a.allowed_value_id.toString()} value={a.allowed_value_id.toString()}>
                                                                  {a.value.toString()}
                                                              </Select.Option>
                                                          )
                                                  )
                                                : []}
                                            <Select.Option
                                                value={"add-button"}
                                                children={
                                                    <Button size="small" onClick={() => openNewValueModal(attr[`name_${currentCountryName}`])} icon={<PlusOutlined />}>
                                                        Add New
                                                    </Button>
                                                }
                                            />
                                        </Select>
                                    </Form.Item>
                                </div>
                            ) : (
                                <div style={{ marginBottom: "0.5rem" }}>
                                    <span style={{ marginLeft: "5px" }}>{attr[`name_${currentCountryName}`] as string}</span>
                                    {": "}
                                    <Form.Item style={{ width: "400px" }} name={attr[`name_${currentCountryName}`] as string}>
                                        <Select
                                            placeholder={attr[`name_${currentCountryName}`] as string}
                                            options={
                                                Array.isArray(attr.attribute_values)
                                                    ? attr.attribute_values.map((a: PrimaryAttributesType<{ allowed_value_id: number; value: string }>) => ({
                                                          label: a.value,
                                                          value: a.allowed_value_id,
                                                      }))
                                                    : []
                                            }
                                            mode="multiple"
                                            tokenSeparators={[","]}
                                            allowClear
                                        />
                                    </Form.Item>
                                </div>
                            )}
                        </div>
                    ))}

                    <Form.Item style={{ width: "400px", marginTop: "0.8rem" }}>
                        <Popconfirm
                            placement="right"
                            title={
                                popconfirmData && popconfirmData.length > 0 ? (
                                    <Table
                                        columns={[
                                            {
                                                title: "Attribute",
                                                dataIndex: "name",
                                            },
                                            {
                                                title: "Value",
                                                dataIndex: "value",
                                            },
                                        ]}
                                        dataSource={popconfirmData}
                                        size="small"
                                        pagination={false}
                                        bordered
                                    />
                                ) : (
                                    <div>No selected Attributes</div>
                                )
                            }
                            onConfirm={submitForm}
                            okText="Apply"
                            cancelText="Cancel" /*open={loading ? true : undefined}*/
                            onOpenChange={(e) => setIsPopConfirmOpen(e)}
                            // open={popconfirmData?.length === 0 ? false : undefined}
                            icon={
                                popconfirmData && popconfirmData.length > 0 ? (
                                    <div style={{ fontSize: "26px", color: "darkred", border: "1px solid lightgray", borderRadius: "3px", padding: "0 0.1rem" }}>{popconfirmData?.length}</div>
                                ) : (
                                    <ExclamationCircleOutlined style={{ color: "red" }} />
                                )
                            }
                        >
                            <Button type="default">Apply to all</Button>
                        </Popconfirm>
                        <Button htmlType="button" danger style={{ marginLeft: "2rem" }} onClick={resetForm}>
                            Reset form
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </Col>
    );
};

export default ApplyToAll;
