import { Button, Form, Image, InputNumber, Popover, Select, Spin, Table, Tag, Tooltip } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AttributesType, InputType, PrimaryAttributesType, ProductType } from "../../pages/TopProductAttributes";
import openNotification from "../../../../shared/MessagesInfo/WarningBox";
import axios from "axios";
import showMessage from "../../../../shared/MessagesInfo/message";
import { CloseOutlined, FieldNumberOutlined, Loading3QuartersOutlined, PlusOutlined } from "@ant-design/icons";
import { ColumnTypes } from "../AttributeMappingRule/TabPaneContent";
import type { InputRef } from "antd";
import numberWithCommas from "../../../../shared/utils/numberUtils";

const EditableContext = React.createContext<any>(null);

interface EditableRowProps {
    index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: any;
    editable: boolean;
    dataIndex: keyof AttributesType;
    record: ProductType;
    currentCountryName: string;
    handleSave: (record: ProductType, inputType: { id: number; type: InputType } | undefined) => void;
    primaryAttributes: PrimaryAttributesType<any>[];
    showModal: (attributeObj: { name: string | undefined; attribute_id: number | undefined; product: ProductType }) => void;
    setProducts: React.Dispatch<React.SetStateAction<ProductType[]>>;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    primaryAttributes,
    currentCountryName,
    setProducts,
    showModal,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef: any = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;
    const [loadingSaveRange, setLoadingSaveRange] = useState(false);
    const [loadingSaveSelect, setLoadingSaveSelect] = useState(false);
    const [loadingSaveMultiSelect, setLoadingSaveMultiSelect] = useState(false);
    ////// type of input
    const [inputType, setInputType] = useState<{ id: number; type: InputType } | undefined>();

    useEffect(() => {
        if (editing) {
            inputRef.current?.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        //setInputType(undefined);
        setEditing(!editing);
        const primaryAttributeArr = record.attributes;
        const findDataInputValue = primaryAttributeArr.find((item: AttributesType) => item.attribute_name === title?.props.children[0]);
        // console.log(findDataInputValue);
        //  type and id
        const id = primaryAttributeArr.find((item: AttributesType) => item.attribute_name === title?.props.children[0])?.attribute_id!;
        const type = primaryAttributeArr.find((item: AttributesType) => item.attribute_name === title?.props.children[0])?.attribute_type as InputType;
        // setInputType({
        //     id: id,
        //     type: type,
        // });
        if (type === "range") {
            form.setFieldsValue({ [dataIndex]: findDataInputValue?.attr_value /* record[dataIndex]*/ });
            setInputType({
                id: id,
                type: type,
            });
            return;
        }

        if (type === "select" || type === "yesno") {
            form.setFieldsValue({ [dataIndex]: findDataInputValue?.attribute_allowed_value_id /* record[dataIndex]*/ });
            setInputType({
                id: id,
                type: type,
            });
            return;
        }
        if (type === "multiselect") {
            const selectedOptions = record.attributes
                .filter((attr: AttributesType) => attr.attribute_id === id && attr.attribute_allowed_value_id)
                .map((item) => item.attribute_allowed_value_id?.toString());

            form.setFieldsValue({ [dataIndex]: selectedOptions /* record[dataIndex]*/ });
            setInputType({
                id: id,
                type: type,
            });
            return;
        }
    };

    // console.log(inputType);

    const save = async (e?: any) => {
        try {
            let values = await form.validateFields();
            if (values.attributes === "add-button") {
                toggleEdit();
                return;
            }
            if (inputType?.type === "range") {
                if (isNaN(Number(values.attributes))) {
                    openNotification("Entered input is not number!");

                    toggleEdit();
                    return;
                }

                const findPrimaryAttribute = primaryAttributes.find((attr) => attr[`name_${currentCountryName}`] === title?.props.children[0]) as PrimaryAttributesType<any>;

                if (Number(values.attributes) < Number(findPrimaryAttribute.min_value) || Number(values.attributes) > Number(findPrimaryAttribute.max_value)) {
                    openNotification(
                        `Range for ${findPrimaryAttribute[`name_${currentCountryName}`]} attriubte have to be between ${findPrimaryAttribute.min_value} and ${findPrimaryAttribute.max_value}`
                    );
                    toggleEdit();
                    return;
                }

                // RANGE
                const attribute_id = primaryAttributes.find((attr) => attr[`name_${currentCountryName}`] === title?.props.children[0])?.id;
                const product_attribute_id = record.attributes.find((attr) => attr.attribute_id === attribute_id)?.product_attribute_id;

                if (Number(values.attributes) === Number(record.attributes.find((attr) => attr.attribute_id === attribute_id)?.attr_value) && Number(values.attributes) !== 0) {
                    toggleEdit();
                    return;
                }
                setLoadingSaveRange(true);

                const obj = {
                    attribute_id: attribute_id,
                    product_id: record.product_id,
                    value: /*Number(values.attributes) === 0 ? null :*/ Number(values.attributes),
                    ...(product_attribute_id && { type: "range", product_attribute_id: product_attribute_id }),
                    // type: "range",
                    attribute_allowed_value_id: null,
                    //product_attribute_id: product_attribute_id,
                };
                const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/attributes/${product_attribute_id ? "update" : "assign"}-product-attribute`, obj);
                showMessage(data.message);
                toggleEdit();
                handleSave({ ...record, ...values, columnName: title?.props.children[0], new_product_attribute_id: data.data }, inputType);
                setLoadingSaveRange(false);
            }
            if (inputType?.type === "select" || inputType?.type === "yesno") {
                setLoadingSaveSelect(true);
                const attribute_id = primaryAttributes.find((attr) => attr[`name_${currentCountryName}`] === title?.props.children[0])?.id;
                const product_attribute_id = record.attributes.find((attr) => attr.attribute_id === attribute_id)?.product_attribute_id;

                const obj = product_attribute_id
                    ? {
                          product_id: record.product_id,
                          value: null,
                          ...(product_attribute_id && { type: inputType?.type, product_attribute_id: product_attribute_id }),
                          type: inputType?.type,
                          attribute_allowed_value_id: Number(values.attributes), // here is wrong id !!!
                      }
                    : {
                          attribute_allowed_value_id: Number(values.attributes),
                          attribute_id: attribute_id,
                          product_id: record.product_id,
                          value: null,
                      };
                const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/attributes/${product_attribute_id ? "update" : "assign"}-product-attribute`, obj);
                showMessage(data.message);

                /////////+++++++++++++++++++++++++++++++++
                const findAttributesOption = primaryAttributes.find((attr) => attr.id === inputType?.id)?.attribute_values;
                const selectedOption = findAttributesOption.find((a: PrimaryAttributesType<{ allowed_value_id: number; value: string }>) => a.allowed_value_id === values.attributes);
                const selectedOptionId = values.attributes;
                // console.log(selectedOptionId);
                values = { attributes: selectedOption.value };
                handleSave({ ...record, attribute_allowed_value_id: selectedOptionId, ...values, columnName: title?.props.children[0], new_product_attribute_id: data.data }, inputType);
                toggleEdit();
                setLoadingSaveSelect(false);
            }
            if (inputType?.type === "multiselect") {
                setLoadingSaveMultiSelect(true);
                const oldSelectedOptions = record.attributes
                    .filter((attri) => attri.attribute_type === "multiselect" && attri.attribute_allowed_value_id && attri.attribute_name === title?.props.children[0])
                    .map((attr) => attr.attribute_allowed_value_id);
                const idForDelete: number[] = [];
                const idForSave: number[] = [];

                oldSelectedOptions.forEach((oldOpition) => {
                    if (!values.attributes.map((idStr: string) => Number(idStr)).includes(Number(oldOpition))) {
                        idForDelete.push(Number(oldOpition));
                    }
                });

                values.attributes
                    .map((idStr: string) => Number(idStr))
                    .forEach((newOptions: number) => {
                        if (!oldSelectedOptions.includes(newOptions)) {
                            idForSave.push(newOptions);
                        }
                    });

                for (const element of idForDelete) {
                    try {
                        await axios.post(`${process.env.REACT_APP_URL_API}/prices/attributes/delete-product-attribute`, {
                            product_attribute_id: record.attributes.find((attr) => attr.attribute_allowed_value_id === element)?.product_attribute_id,
                        });

                        setProducts((curr) =>
                            curr.map((product) =>
                                product.product_id === record.product_id
                                    ? {
                                          ...product,
                                          attributes: product.attributes.filter((attr) => attr.attribute_allowed_value_id && !idForDelete.includes(attr.attribute_allowed_value_id)),
                                      }
                                    : product
                            )
                        );
                    } catch (err) {
                        console.log(err);
                    }
                }

                let newProductAttributeIds: { optionId: number; new_product_attribute_id: number }[] = [];

                for (const element of idForSave) {
                    const attribute_id = primaryAttributes.find((attr) => attr[`name_${currentCountryName}`] === title?.props.children[0])?.id;
                    const obj = {
                        attribute_allowed_value_id: Number(element),
                        attribute_id: attribute_id,
                        product_id: record.product_id,
                        value: null,
                    };

                    const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/attributes/assign-product-attribute`, obj);

                    newProductAttributeIds.push({ optionId: Number(element), new_product_attribute_id: data.data });
                }
                if (idForSave.length > 0) {
                    const findAttributesOption: { allowed_value_id: number; value: string }[] = primaryAttributes.find((attr) => attr.id === inputType?.id)?.attribute_values;
                    const filterSelectedOption = findAttributesOption.filter((selectedOption) => values.attributes.map((idStr: string) => Number(idStr)).includes(selectedOption.allowed_value_id));

                    handleSave({ ...record, newAttributeArray: filterSelectedOption, ...values, columnName: title?.props.children[0], new_product_attribute_ids: newProductAttributeIds }, inputType);
                    showMessage("Product attribute updated");
                } else {
                    showMessage("Product attribute updated");
                }
                setLoadingSaveMultiSelect(false);
                toggleEdit();
            }
        } catch (errInfo) {
            console.log("Save failed:", errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            inputType?.type === "range" ? (
                <Form.Item style={{ margin: 0 }} name={dataIndex}>
                    {/* <Input
                        ref={inputRef}
                        onPressEnter={save}
                        onBlur={save}
                        suffix={loadingSaveRange ? <Spin size="small" indicator={<Loading3QuartersOutlined style={{ fontSize: 14 }} spin />} /> : <span />}
                    /> */}
                    <InputNumber
                        //style={{ width: "100%" }}
                        ref={inputRef}
                        placeholder={primaryAttributes.find((attr) => attr.id === inputType?.id)![`name_${currentCountryName}`]}
                        // min={0}
                        // max={Number(primaryAttributes.find((attr) => attr.id === inputType?.id)?.max_value)}
                        onPressEnter={save}
                        onBlur={save}
                        addonAfter={loadingSaveRange ? <Spin size="small" indicator={<Loading3QuartersOutlined style={{ fontSize: 14 }} spin />} /> : <FieldNumberOutlined />}
                        controls={false}
                    />
                </Form.Item>
            ) : inputType?.type === "yesno" ? (
                <Form.Item style={{ margin: 0 }} name={dataIndex}>
                    <Select
                        ref={inputRef}
                        autoFocus
                        defaultOpen
                        // showSearch
                        onChange={save}
                        onBlur={() => toggleEdit()}
                        //options={primaryAttributes.find((attr) => attr.id === inputType?.id)?.attribute_values.map((a: any) => ({ label: a.value, value: a.allowed_value_id }))}
                        loading={loadingSaveSelect}
                    >
                        {primaryAttributes
                            .find((attr) => attr.id === inputType?.id)
                            ?.attribute_values.map((a: any) => (
                                <Select.Option key={a.allowed_value_id} value={a.allowed_value_id}>
                                    {a.value}
                                </Select.Option> /*{ label: a.value, value: a.allowed_value_id })*/
                            ))}
                        <Select.Option
                            value={"add-button"}
                            children={
                                <Button
                                    size="small"
                                    onClick={() =>
                                        // showModal({
                                        //     name: showPrimaryAttrName(primaryAttributes.find((attr) => attr.id === inputType?.id)), // [`name_${currentCountryName}`])
                                        //     attribute_id: record.attribute_id,
                                        // })
                                        showModal({
                                            name: primaryAttributes.find((attr) => attr.id === inputType?.id)![`name_${currentCountryName}`]!,
                                            attribute_id: primaryAttributes.find((attr) => attr.id === inputType?.id)!.id,
                                            product: record,
                                        })
                                    }
                                    icon={<PlusOutlined />}
                                >
                                    Add New
                                </Button>
                            }
                        />
                    </Select>
                </Form.Item>
            ) : inputType?.type === "select" ? (
                <Form.Item style={{ margin: 0 }} name={dataIndex}>
                    <Select
                        ref={inputRef}
                        autoFocus
                        defaultOpen
                        showSearch
                        onChange={save}
                        onBlur={() => toggleEdit()}
                        optionFilterProp="label"
                        filterOption={(input, option: any) => (option?.value === "add-button" ? false : (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase()))}
                        loading={loadingSaveSelect}
                    >
                        {primaryAttributes
                            .find((attr) => attr.id === inputType?.id)
                            ?.attribute_values.map((a: any) => (
                                <Select.Option key={a.allowed_value_id} value={a.allowed_value_id}>
                                    {a.value}
                                </Select.Option> /*{ label: a.value, value: a.allowed_value_id })*/
                            ))}
                        <Select.Option
                            value={"add-button"}
                            children={
                                <Button
                                    size="small"
                                    onClick={() =>
                                        // showModal({
                                        //     name: showPrimaryAttrName(primaryAttributes.find((attr) => attr.id === inputType?.id)), // [`name_${currentCountryName}`])
                                        //     attribute_id: record.attribute_id,
                                        // })
                                        showModal({
                                            name: primaryAttributes.find((attr) => attr.id === inputType?.id)![`name_${currentCountryName}`]!,
                                            attribute_id: primaryAttributes.find((attr) => attr.id === inputType?.id)!.id,
                                            product: record,
                                        })
                                    }
                                    icon={<PlusOutlined />}
                                >
                                    Add New
                                </Button>
                            }
                        />
                    </Select>
                </Form.Item>
            ) : (
                <Form.Item
                    style={{ margin: 0 }}
                    name={dataIndex}
                    initialValue={record.attributes.filter((attr: AttributesType) => attr.attribute_id === inputType?.id).map((attr: AttributesType) => attr.attribute_allowed_value_id?.toString())}
                >
                    <Select
                        ref={inputRef}
                        autoFocus
                        showSearch
                        placeholder="Multiselect"
                        mode="multiple"
                        tokenSeparators={[","]}
                        defaultOpen
                        allowClear
                        onBlur={save}
                        optionLabelProp="label"
                        filterOption={(input: string, option?: { label: string; value: string }) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                        loading={loadingSaveMultiSelect}
                        options={primaryAttributes
                            .find((attr) => attr[`name_${currentCountryName}`] === title?.props.children[0])
                            ?.attribute_values.map((a: any) => ({ label: a.value, value: a.allowed_value_id.toString() }))}
                    />
                </Form.Item>
            )
        ) : (
            <div className="editable-cell-value-wrap" style={{ paddingInlineEnd: 24, minHeight: "32px", borderRadius: "8px" }} onClick={toggleEdit}>
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

interface TopProductAttributesTableProps {
    currentCountryName: string;
    primaryAttributes: PrimaryAttributesType<any>[];
    setProducts: React.Dispatch<React.SetStateAction<ProductType[]>>;
    products: ProductType[];
    count: number;
    page: number;
    loading: boolean;
    showModal: (attributeObj: { name: string | undefined; attribute_id: number | undefined; product: ProductType }) => void;
    onPaginationChange: (page: number) => void;
}

const TopProductAttributesTable = (props: TopProductAttributesTableProps) => {
    const { currentCountryName, primaryAttributes, setProducts, products, count, page, loading, showModal, onPaginationChange } = props;
    const [loaidngDelete, setLoadingDelete] = useState<{ name: string; product_id: number }>();

    const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
        {
            title: "Product",
            dataIndex: `product_name`,
            width: "400px",
            render: (_, record: any) => (
                <span
                    className="top-attribute-product-name"
                    style={{ cursor: "pointer" }}
                    onClick={() => window.open(record.product_link, "_blank", "noreferrer")}
                >{`${record.brand_name} ${record.product_name}`}</span>
            ),
        },
        {
            title: "Image",
            dataIndex: "product_image",
            width: "70px",
            render: (text) => (
                <Popover
                    placement="rightTop"
                    content={
                        <div style={{ width: "500px" }}>
                            <Image src={text} preview={false} />
                        </div>
                    }
                    arrowPointAtCenter
                >
                    <Image src={text} width={40} preview={false} />
                </Popover>
            ),
        },
        ...primaryAttributes
            .map((a: PrimaryAttributesType<any>) => {
                const attributeObject = {
                    title: (
                        <div>
                            {a[`name_${currentCountryName}`]}{" "}
                            {a.type === "range" && (
                                <span style={{ color: "blue" }}>
                                    ({numberWithCommas(a.min_value.replace(".", ","))} - {numberWithCommas(a.max_value.replace(".", ","))})
                                </span>
                            )}{" "}
                        </div>
                    ) as React.ReactNode,
                    className: a.type !== "multiselect" ? "product-attributes-remove-value-left" : "",
                    dataIndex: "attributes",
                    width: "300px",
                    editable: true,
                    render: (text: any, record: any) => {
                        const findAttribute = record.attributes.find((product: any) => product.attribute_id === a.id);
                        return a.type !== "multiselect"
                            ? findAttribute?.attr_value
                            : record.attributes
                                  .filter((attr: AttributesType) => attr.attribute_id === a.id && attr?.attr_value)
                                  .map(
                                      (attr: AttributesType) => (
                                          <Tag color="blue" style={{ marginLeft: "0px", marginRight: "4px", marginBottom: "2px", fontSize: "14px" }}>
                                              {attr?.attr_value}
                                          </Tag>
                                      ) /*attr.attr_value*/
                                  );
                        //.join(", ");
                    },
                };

                return [
                    attributeObject,
                    ...(a.type !== "multiselect"
                        ? [
                              {
                                  title: "",
                                  dataIndex: `name_${currentCountryName}`,
                                  width: "10px",
                                  className: "product-attributes-remove-value-right top-attribute-delete-button",
                                  align: "center" as "center",
                                  render: (text: string, record: any) => {
                                      return record.attributes.find((attr: AttributesType) => attr.attribute_name === a[`name_${currentCountryName}`])?.product_attribute_id ? (
                                          <Tooltip title={`Delete: ${a[`name_${currentCountryName}`]}`} destroyTooltipOnHide>
                                              <Button
                                                  size="small"
                                                  style={{ color: "#ffaaa5" }}
                                                  loading={loaidngDelete?.name === a[`name_${currentCountryName}`] && loaidngDelete?.product_id === record.product_id}
                                                  icon={/*!record.loadingDelete ? */ <CloseOutlined /> /*: ""*/}
                                                  disabled={!record.attributes.find((attr: AttributesType) => attr.attribute_name === a[`name_${currentCountryName}`])?.product_attribute_id}
                                                  onClick={() => removeValue(record, a.id, a[`name_${currentCountryName}`], a.type /*, record.attribute_type*/)}
                                                  type="ghost"
                                              />
                                          </Tooltip>
                                      ) : (
                                          <Button
                                              size="small"
                                              style={{ color: "#ffaaa5" }}
                                              loading={loaidngDelete?.name === a[`name_${currentCountryName}`] && loaidngDelete?.product_id === record.product_id}
                                              icon={/*!record.loadingDelete ? */ <CloseOutlined /> /*: ""*/}
                                              disabled={!record.attributes.find((attr: AttributesType) => attr.attribute_name === a[`name_${currentCountryName}`])?.product_attribute_id}
                                              onClick={() => removeValue(record, a.id, a[`name_${currentCountryName}`], a.type /*, record.attribute_type*/)}
                                              type="ghost"
                                          />
                                      );
                                  },
                              },
                          ]
                        : []),
                ];
            })
            .flat(),
    ];

    const handleSave = (row: ProductType, inputType: { id: number; type: InputType } | undefined) => {
        if (inputType?.type === "range") {
            setProducts((curr: any) =>
                curr.map((product: ProductType) =>
                    product.product_id === row.product_id
                        ? {
                              ...product,
                              attributes: product.attributes.map((attr: AttributesType) =>
                                  attr.attribute_name === row[`columnName` as keyof typeof row] ? { ...attr, attr_value: row.attributes, product_attribute_id: row.new_product_attribute_id } : attr
                              ),
                          }
                        : product
                )
            );
        }
        if (inputType?.type === "select" || inputType?.type === "yesno") {
            setProducts((curr: any) =>
                curr.map((product: ProductType) =>
                    product.product_id === row.product_id
                        ? {
                              ...product,
                              attributes: product.attributes.map((attr: AttributesType) =>
                                  attr.attribute_name === row[`columnName` as keyof typeof row]
                                      ? { ...attr, attribute_allowed_value_id: row.attribute_allowed_value_id, attr_value: row.attributes, product_attribute_id: row.new_product_attribute_id }
                                      : attr
                              ),
                          }
                        : product
                )
            );
        }

        if (inputType?.type === "multiselect") {
            const attriubteObj = primaryAttributes.find((attr) => attr.type === "multiselect" && attr[`name_${currentCountryName}`] === row.columnName);

            const objWithNewProductAttributeIds: any = row.new_product_attribute_ids;
            const attibuteArray =
                attriubteObj &&
                Array.isArray(row.newAttributeArray) &&
                row.newAttributeArray
                    ?.map((attr: any) => {
                        if (objWithNewProductAttributeIds.find((a: { optionId: number; new_product_attribute_id: number }) => a.optionId === attr.allowed_value_id)) {
                            return {
                                attr_value: attr.value,
                                attribute_allowed_value_id: attr.allowed_value_id,
                                attribute_id: attriubteObj?.id,
                                attribute_name: attriubteObj[`name_${currentCountryName}` as keyof typeof attriubteObj],
                                attribute_type: attriubteObj?.type,
                                max_value: attriubteObj?.max_value,
                                min_value: attriubteObj?.min_value,
                                product_attribute_id: objWithNewProductAttributeIds.find((el: { optionId: number; new_product_attribute_id: number }) => el.optionId === attr.allowed_value_id)
                                    .new_product_attribute_id,
                            };
                        } else {
                            return null;
                        }
                    })
                    .filter((el) => el);
            setProducts((curr: any) =>
                curr.map((product: ProductType) => {
                    const newAttributeArrayWihtNewElement = [
                        ...product.attributes.filter(
                            (attr) => attr.attribute_type !== "multiselect" || (attr.attribute_type === "multiselect" && attr[`name_${currentCountryName}` as keyof typeof attr] !== row.columnName)
                        ),
                        ...(attibuteArray ? attibuteArray : []),
                    ];

                    //}
                    return product.product_id === row.product_id
                        ? {
                              ...product,
                              attributes: newAttributeArrayWihtNewElement,
                          }
                        : product;
                })
            );
        }
    };

    const removeValue = async (record: ProductType, attribute_id: number, attribute_name: string, type: InputType) => {
        try {
            setLoadingDelete({ name: attribute_name, product_id: record.product_id });
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/attributes/delete-product-attribute`, {
                product_attribute_id: record.attributes.find((attr) => attr.attribute_name === attribute_name)?.product_attribute_id,
            });
            if (type === "range") {
                setProducts((curr: any) =>
                    curr.map((product: ProductType) =>
                        product.product_id === record.product_id
                            ? {
                                  ...product,
                                  attributes: product.attributes.map((attr: AttributesType) =>
                                      attr.attribute_name === attribute_name ? { ...attr, attr_value: null, product_attribute_id: null } : attr
                                  ),
                              }
                            : product
                    )
                );
            }
            if (type === "select" || type === "yesno") {
                setProducts((curr: any) =>
                    curr.map((product: ProductType) =>
                        product.product_id === record.product_id
                            ? {
                                  ...product,
                                  attributes: product.attributes.map((attr: AttributesType) =>
                                      attr.attribute_name === attribute_name ? { ...attr, attribute_allowed_value_id: null, attr_value: null, product_attribute_id: null } : attr
                                  ),
                              }
                            : product
                    )
                );
            }
            showMessage(data.message);
            setLoadingDelete(undefined);
        } catch (err) {
            console.log(err);
        }
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: ProductType) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                primaryAttributes,
                currentCountryName: currentCountryName,
                setProducts: setProducts,
                showModal: showModal,
                handleSave,
            }),
        };
    });

    return (
        <Table
            components={components}
            rowClassName={() => "editable-row"}
            bordered
            dataSource={products}
            columns={columns as ColumnTypes}
            pagination={{ pageSize: 100, total: count, showSizeChanger: false, onChange: onPaginationChange, current: page }}
            rowKey={(record: any) => record.product_id}
            loading={loading}
        />
    );
};

export default TopProductAttributesTable;
